import { postAPI } from '../apiServices';

// return the user data from the session storage
export const setLoggedUser = () => {
    console.log("setLoggedUser");
    loggedUserData();
}

async function loggedUserData() {
    console.log("loggedUserData");
    let response = await postAPI("/user/getUserInfo", {});
    if (response.status === 1) {
        try {
            let data = JSON.parse(JSON.stringify(response.payload[0]));
            //data = data[0];
            console.log("data")
            console.log(data);
            data.companyNameFirstLetter = data.companyName ? data.companyName.charAt(0).toUpperCase() : ""
            data.personalProfileCompleted = data.companyName ? true : false;
            data.gstInfoCompleted = data.gstin ? data.gstin.value ? true : false : false;
            data.fssaiInfoCompleted = data.fssai ? data.fssai.value ? true : false : false;
            data.pickupAddressCompleted = data.pickupAddress ? data.pickupAddress.zipcode ? true : false : false;
            data.bankInfoCompleted = data.bankDetails ? data.bankDetails.accountNumber ? true : false :false;
            data = { ...data.companyNameFirstLetter, ...data }
            console.log(data)
            window.localStorage.setItem("LoggedUser", JSON.stringify(data));
            window.localStorage.setItem("LoggedUser", JSON.stringify(data));
            window.localStorage.setItem("username", JSON.stringify(data.firstName));
            window.localStorage.setItem("username", JSON.stringify(data.firstName));
            console.log("test data");
            return true;
            //setLoggedUser(data)
        } catch (er) { console.log(er) }
    }
}

export const getUser = () => {
    // const userStr = sessionStorage.getItem('LoggedUser');
    let userStr = JSON.parse(localStorage.getItem("LoggedUser"))
    if (userStr) return userStr;
    else return null;
}

// return the token from the session storage
export const getToken = () => {
    // return sessionStorage.getItem('LoggedUser') || null;
    return JSON.parse(localStorage.getItem("LoggedUser")) || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
}