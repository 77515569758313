// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import axios from 'axios'
const axios = require("axios");

// const API_BASE = "https://sellerucliqapi.bytesbrick.com:4004/v1";
const API_BASE = "https://sellerapi.ucliq.in/v1"
// const API_BASE = "http://localhost:3008/api/v1";
const GSTKEY = "09b9ff91aaa26d1fa548c29f7ff87485";
//let token = JSON.parse(window.localStorage.getItem("TOKEN"));
//let token = window.localStorage.getItem("TOKEN");
export const postAPI = async function (url, data) {
  let _token = JSON.parse(window.localStorage.getItem("TOKEN"));
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "POST",
      url: url,
      data: data,
      headers: headers,
    };
    const postApiRes = await axios(requestOptions);
    return postApiRes.data;
  } catch (err) {
    return { data: { error: "Server down" } };
  }
};

export const get = async function (url, data) {
  let _token = JSON.parse(window.localStorage.getItem("TOKEN"));
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "GET",
      url: url,
      data: data,
      headers: headers,
    };
    const getApiRes = await axios(requestOptions);
    return getApiRes.data;
  } catch (err) {
    return { data: { error: "Server down" } };
  }
};
export const put = async function (url, data) {
  //   data.token = token
  //   let apires = await HttpClient.put(API_BASE + url, data).toPromise()
  //   return apires
};
export const common = async function (url, data) {
  //   let apires = await HttpClient.post(API_BASE + url, data).toPromise()
  //   return apires
};

export const uploadImageAPI = async function (url, fileData) {
  let token = JSON.parse(window.localStorage.getItem("TOKEN"));
  var formData = new FormData();
  for (let i = 0; i < fileData.length; i++) {
    formData.append("files", fileData[i]);
  }
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer  " + token,
  };
  try {
    const postApiRes = await axios({
      method: "POST",
      url: API_BASE + url,
      data: formData,
      headers: headers,
    });
    return postApiRes.data;
  } catch (err) {
    return { data: { error: "Server down" } };
  }
};

export const checkGSTNumber = async function (GSTNumber) {
  var config = {
    method: "get",
    url: "http://sheet.gstincheck.co.in/check/" + GSTKEY + "/" + GSTNumber,
    headers: {},
  };
  try {
    const postApiRes = await axios(config);
    return postApiRes.data;
  } catch (err) {
    return { data: { error: "Server down" } };
  }
};

export const getBankDetail = async function (IFSC){
  var config = {
    method:"get",
    url: "https://ifsc.razorpay.com/"+IFSC,
    headers:{}
  };
  try{
    const postApis = await axios(config);
    setTimeout(() => {
    }, 3500);
    return postApis.data;
  }catch(err){
    return { data: { error: "Server down" } };
  }
}

// For base 64
function createBase64(fileData) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      resolve(reader.result);
    };
  });
}
